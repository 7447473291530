<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="primary">
      <div class="row">
        <div class="col-12">
          <h1>Заяви та звернення</h1>
          <p class="mb-1">
            Вхідні заяви та звернення від жителів. <br />Працівник бачить лише
            заявки до відділу, до якого він належить
          </p>
          <div class="mb-2 d-flex">
            <b-form-select v-model="selectedStatus" class="mr-1">
              <b-form-select-option
                :value="o.value"
                v-for="o in statuses"
                :key="o.value"
              >
                {{ o.text }}
              </b-form-select-option>
            </b-form-select>
            <b-button @click="getTickets(true)" variant="primary">
              Фільтрувати
            </b-button>
          </div>
        </div>
        <div class="col-12">
          <ticketItem v-for="t in all_tickets" :key="t.id" :t="t" />
        </div>
        <div class="col-12 d-flex my-2 justify-content-center">
          <b-button v-if="last_doc" variant="primary" @click="getTickets">
            Завантажити ще {{ per_page }}
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ticketItem from "@/components/ticket-list-item.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ticketItem,
  },
  data() {
    return {
      isLoading: false,
      selectedStatus: 0,
      ticket_types: [],
      departments: [],
      all_tickets: [],
      page: 1,
      per_page: 24,
      last_doc: null,
    };
  },
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    statuses() {
      let statuses = this.datasets.statuses;
      let options = statuses.map((x) => ({
        text: x.name,
        value: x.id,
      }));

      return options;
    },
  },
  methods: {
    async getTicketsWorker(filter) {
      console.log("worker");
      if (filter == true) {
        this.all_tickets = [];
      }
      this.isLoading = true;
      let workerDepartment = this.$store.state.workerDepartment;
      var args = {
        collectionName: "tickets",
        where: ["department", "==", workerDepartment],
        where2: ["status", "==", this.selectedStatus],
        order: ["created_at", "desc"],
        limitBy: Number(this.per_page),
      };
      if (this.page !== 1 && this.last_doc) {
        args["startAfter"] = this.last_doc;
      }
      var result = await this.$db.getCollection(args);
      result.docs.forEach((doc) => {
        this.all_tickets.push(doc);
      });
      if (result.docs.length == this.per_page) {
        this.last_doc = result.lastVisible;
        this.page++;
      } else {
        this.last_doc = null;
      }
      this.isLoading = false;
    },
    async getTicketsAdmin(filter) {
      console.log("admin");
      if (filter == true) {
        this.all_tickets = [];
      }

      this.isLoading = true;
      var args = {
        collectionName: "tickets",
        order: ["created_at", "desc"],
        where: ["status", "==", this.selectedStatus],
        limitBy: Number(this.per_page),
      };
      if (this.page !== 1 && this.last_doc) {
        args["startAfter"] = this.last_doc;
      }
      var result = await this.$db.getCollection(args);
      result.docs.forEach((doc) => {
        this.all_tickets.push(doc);
      });
      if (result.docs.length == this.per_page) {
        this.last_doc = result.lastVisible;
        this.page++;
      } else {
        this.last_doc = null;
      }

      this.isLoading = false;
    },
    async getTickets(filter) {
      let doFilter = false;
      if (filter == true) {
        doFilter = true;
      }
      if (this.$acl.check("admin")) {
        this.getTicketsAdmin(doFilter);
      } else if (this.$acl.check("worker")) {
        this.getTicketsWorker(doFilter);
      }
    },
  },
  mounted() {
    this.getTickets(false);
  },
};
</script>

<style lang="scss" scoped>
.search {
  padding-bottom: 20px;
  input {
    height: 35px;
    width: 280px;

    &::placeholder {
      padding-left: 6px;
    }
  }
  .dropdown.b-dropdown {
    height: 33px;
    margin-left: 10px;
  }
}
.user_info {
  margin-top: 20px;
  .user {
    .b-avatar {
      margin-right: 10px;
    }
  }
}
.text {
  margin-top: 20px;
}
</style>
