<template>
  <div
    class="status-badge"
    :style="{ backgroundColor: statusInfo ? statusInfo.color : '#f00' }"
  >
    <span v-if="statusInfo">
      {{ statusInfo.name }}
    </span>
    <span v-else>----</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["status", "statusObj"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    statuses() {
      return this.datasets.statuses;
    },
    statusInfo() {
      var status = {
        id: -1,
        name: "Завантаження...",
        color: "#f00",
      };
      if (this.statusObj !== undefined) {
        status = this.statusObj;
      } else {
        status = this.statuses.find((el) => el.id == this.status);
      }

      return status;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-badge {
  display: inline-flex;
  border-radius: 6px;
  padding: 0.45rem 0.6rem;
  background-color: #666;
  color: #fff;
  line-height: 1;
  font-weight: 600;
}
</style>
